import React from "react";
import './Nav.css'
import { Tag } from "antd"
import top from '../img/top.svg'

class Nav extends React.Component {
    render() {
        return (
            <div className="nav">
                <h1>工具</h1>
                <h1>案例</h1>
                <h1>数据</h1>
                <h1>教程</h1>
                <h1>资讯</h1>
                <h1>论坛</h1>
            </div>
        )
    }
}

class Top extends React.Component {
    render() {
        return (
            <a href="#home" className="go-to-top">
                <img src={top}/>
            </a>
        )
    }
}

class Links extends React.Component {
    render() {
        return (
            <div className="link-tags" style={{marginTop: "8px"}}>
                {
                    this.props.drupal?
                        <a href={this.props.drupal} target="_blank"><Tag color="#4B5362">简介</Tag></a>:
                        <Tag color="#9F9F9F" className="no-hover">暂无教程</Tag>
                }

                <a href={this.props.website} target="_blank"><Tag color="#5F8FB2">网址</Tag></a>
            </div>
        )
    }
}

export { Nav, Top, Links };

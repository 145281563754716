import React from 'react'
import './ResPage.css'
import { Card } from 'antd';
import CBDB from '../img/cbdb.png'
import Incite from '../img/insite.png'
import WorldMap from "../img/worldmap.png";
import ArcGIS from "../img/arcgis.png";
import Juxta from '../img/juxtra.png'
import Excel from "../img/excel.png";
import DocuSky from "../img/docusky.png";
import Lexos from "../img/lexos.png";
import OpenRefine from '../img/openrefine.png'
import Omeka from '../img/omeka.png'
import MySQL from '../img/mysql.png'
import Neo4j from '../img/neo4j.png'
import {Links} from "./Nav";
import { Popover } from "antd";

class Collection extends React.Component{
    render() {
        const gridStyle = {
            width: '50%',
            textAlign: 'center',
        };

        return (
            <div className="resPage collection">
                <Card className="substage-card co-gathering" bordered={false}>
                    <div className="substage">
                        <div className="substage-title">收集</div>
                        <Card title="文本挖掘" className="tool-card">
                            <Card.Grid style={{width: '100%', backgroundColor: '#57635e'}}>
                                <Popover content={<Links website="http://incite.cs.vt.edu/"/>}>
                                    <img src={Incite} style={{maxWidth:"100%"}} />
                                </Popover>

                            </Card.Grid>
                            <Card.Grid style={{width: '100%'}}>
                                <Popover content={<Links website="https://www.gnu.org/software/wget//"/>}>
                                    <h2 style={{color: '#000000', textAlign: "center", lineHeight: "1.8em", marginBottom: 0}}>GNU WGET</h2>
                                </Popover>
                            </Card.Grid>
                        </Card>
                        <Card title="网络分析" className="tool-card">
                            <Card.Grid style={{width: '100%'}}>
                                <Popover content={<Links website="https://projects.iq.harvard.edu/cbdb/home"/>}>
                                    <img src={CBDB} style={{maxWidth:"40%"}} />
                                </Popover>
                            </Card.Grid>
                        </Card>
                        <Card title="地理空间分析" className="tool-card">
                            <Card.Grid style={{width: '100%'}}>
                                <Popover content={<Links website="http://worldmap.harvard.edu/"/>}>
                                    <img src={WorldMap} style={{maxWidth:"100%"}} />
                                </Popover>
                            </Card.Grid>
                            <Card.Grid style={{width: '100%', height: "1.3em"}}>
                                <Popover content={<Links website="https://projects.iq.harvard.edu/cbdb/home"/>}>
                                    <h2 style={{color: '#000000', textAlign: "center", lineHeight: "1em", marginBottom: 0}}>CHGIS</h2>
                                </Popover>
                            </Card.Grid>
                            <Card.Grid style={gridStyle} >
                                <Popover content={<Links website="http://chgis.fas.harvard.edu/"/>}>
                                    <img src={CBDB} style={{maxWidth:"100%"}} />
                                </Popover>

                            </Card.Grid>
                            <Card.Grid style={gridStyle}>
                                <Popover content={<Links website="https://www.arcgis.com/index.html"/>}>
                                    <img src={ArcGIS} style={{maxWidth:"100%"}} />
                                </Popover>

                            </Card.Grid>
                        </Card>

                        <Card title="网络分析" className="tool-card">
                            <Card.Grid style={{width: '100%'}}>
                                <Popover content={<Links website="https://projects.iq.harvard.edu/cbdb/home"/>}>
                                    <img src={CBDB} style={{maxWidth:"40%"}} />
                                </Popover>
                            </Card.Grid>
                        </Card>
                    </div>
                </Card>
                <div className="substage-3">
                    <Card className="substage-card co-screening">
                        <div className="substage">
                            <div className="substage-title">筛选</div>
                            <Card className="tool-card-s">
                                <Card.Grid style={{width: '100%', height: "70px"}}>
                                    <Popover content={<Links website="https://www.juxtasoftware.org/"/>}>
                                        <img src={Juxta} style={{maxWidth:"100%"}} />
                                    </Popover>

                                </Card.Grid>
                                <Card.Grid style={{width: '50%', height: "80px"}}>
                                    <Popover content={<Links website="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions"/>}>
                                        <img src={Excel} style={{maxWidth:"45%"}} />
                                    </Popover>
                                </Card.Grid>
                            </Card>
                        </div>
                    </Card>
                    <Card className="substage-card co-processing">
                        <div className="substage">
                            <div className="substage-title">处理</div>
                            <Card className="tool-card-s">
                                <Card.Grid style={{width: '50%', height: "70px"}}>
                                    <Popover content={<Links website="https://docusky.org.tw/" drupal={"https://pkudh.org/DocuSky"}/>}>
                                        <img src={DocuSky} style={{maxWidth:"100%"}} />
                                    </Popover>

                                </Card.Grid>
                                <Card.Grid style={{width: '25%', height: "70px"}}>
                                    <div className="group-vertical">
                                        <Popover content={<Links website="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions"/>}>
                                            <img src={Excel} style={{maxWidth:"70%"}} />
                                        </Popover>
                                    </div>

                                </Card.Grid>
                                <Card.Grid style={{width: '25%', height: "70px"}}>
                                    <Popover content={<Links website="https://wheatoncollege.edu/academics/special-projects-initiatives/lexomics/lexos-installers/"/>}>
                                        <img src={Lexos} style={{maxHeight:"100%"}} />
                                    </Popover>
                                </Card.Grid>
                                <Card.Grid style={{width: '33%', height: "80px"}}>
                                    <Popover content={<Links website="https://openrefine.org/" drupal="https://programminghistorian.org/en/lessons/fetch-and-parse-data-with-openrefine"/>}>
                                        <img src={OpenRefine} style={{maxWidth:"100%"}} />
                                    </Popover>
                                </Card.Grid>
                                <Card.Grid style={{width: '33%', height: "80px"}}>
                                    <Popover content={<Links website="https://dh.chinese-empires.eu/markus/beta/"/>}>
                                        <h2 style={{color: '#000000', fontSize: "1em", textAlign: "center", lineHeight: "1.3em", marginBottom: 0}}>Markus</h2>
                                    </Popover>

                                </Card.Grid>
                                <Card.Grid style={{width: '34%', height: "80px"}}>
                                    <h2 style={{color: '#000000', fontSize: "1em", textAlign: "center", lineHeight: "1.3em", marginBottom: 0}}>CLAWS</h2>
                                </Card.Grid>
                            </Card>
                        </div>
                    </Card>
                    <Card className="substage-card co-organizing">
                        <div className="substage">
                            <div className="substage-title" style={{marginRight: "3px"}}>组织</div>
                            <Card className="tool-card-s">
                                <Card.Grid style={{width: '50%', height: "70px"}}>
                                    <Popover content={<Links website="https://docusky.org.tw/" drupal="https://pkudh.org/DocuSky"/>}>
                                        <img src={DocuSky} style={{maxWidth:"100%"}} />
                                    </Popover>

                                </Card.Grid>
                                <Card.Grid style={{width: '25%', height: "70px"}}>
                                    <Popover content={<Links website="https://omeka.org/" drupal="https://omeka.org/s/docs/user-manual/"/>}>
                                        <img src={Omeka} style={{maxHeight:"100%"}} />
                                    </Popover>

                                </Card.Grid>
                                <Card.Grid style={{width: '25%', height: "70px"}}>
                                    <Popover content={<Links website="https://wheatoncollege.edu/academics/special-projects-initiatives/lexomics/lexos-installers/"/>}>
                                        <img src={Lexos} style={{maxHeight:"100%"}} />
                                    </Popover>

                                </Card.Grid>
                                <Card.Grid style={{width: '50%', height: "80px"}}>
                                    <Popover content={<Links website="https://www.mysql.com/cn/"/>}>
                                        <img src={MySQL} style={{maxWidth:"100%"}} />
                                    </Popover>

                                </Card.Grid>
                                <Card.Grid style={{width: '50%', height: "80px"}}>
                                    <Popover content={<Links website="https://neo4j.com/"/>}>
                                        <img src={Neo4j} style={{maxWidth:"100%"}} />
                                    </Popover>

                                </Card.Grid>
                            </Card>
                        </div>
                    </Card>
                </div>
            </div>
        )
    }
}

export default Collection;
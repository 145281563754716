import React from 'react'
import 'css-doodle'
import './Landing.css'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

const phaseText = {
    "":"",
    "formulation": "问题形成",
    "collection": "数据收集",
    "analysis": "数据分析",
    "presentation": "结果呈现"
};

const textPosition = {
    "": 0,
    "formulation": 0,
    "collection": "28%",
    "analysis": "55%",
    "presentation": "72%"
};

class Landing extends React.Component {
    render() {
        return (
            <div className='landing'>
                <Doodle rule={`
                    :doodle {
                      @grid: 8 / 18em 18em;
                      @shape: circle;
                    }
                    
                    transition: .2s @r(.6s);
                    border-radius: @pick(100% 0, 0 100%);
                    
                    will-change: transform;
                    transform: scale(@r(.25, 1.25));
                    
                    background: hsla(
                      calc(240 - 6 * @row * @col),
                      70%, 68%, @r.8
                    );
                `}/>
                <h1 className="title">数字人文资源导航</h1>
                <Phases />
            </div>
        )
    }
}

class Doodle extends React.Component {
    render() {
        return(
            <css-doodle>
                {this.props.rule}
            </css-doodle>
        )
    }
}
export default Landing

class Phases extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hover: ''
        };
        this.handleMouseOut = this.handleMouseOut.bind(this);
    }

    handleMouseOver = (label) => {
        this.setState({
            hover: label
        });
    };

    handleMouseOut = () => {
        this.setState({
            hover: ''
        })
    };

    render() {
        return (
            <div className="phases-body">
                <h2>根据<span className="highlight-1">研</span><span className="highlight-3">究</span><span className="highlight-4">阶</span><span className="highlight-5">段</span>进行浏览</h2>
                <ReactCSSTransitionGroup
                    transitionName="example"
                    className="phases"
                    transitionAppear={true}
                    transitionEnterTimeout={500}
                    transitionLeaveTimeout={300}
                    transitionAppearTimeout={1000}>
                    <div className="ph">
                        <a href="#formulation"
                           className="formulation phase"
                           key="formulation"
                           onMouseOut={this.handleMouseOut}
                           onMouseOver={this.handleMouseOver.bind(this, "formulation")}>
                            <i className="right" style={{borderColor: "rgba(67,120,156,0.43)"}}/>
                            <i className="right" style={{borderColor: "rgba(67,120,156,0.72)"}}/>
                            <i className="right" style={{borderColor: "rgba(67,120,156,0.98)"}}/>
                        </a>
                        <p>问题发现</p>
                    </div>
                    <div className="ph">
                        <a href="#collection"
                            className="collection phase"
                            key="collection"
                            onMouseOut={this.handleMouseOut}
                            onMouseOver={this.handleMouseOver.bind(this, "collection")}>
                            <i className="right" style={{borderColor: "rgba(70,160,109,0.43)"}}/>
                            <i className="right" style={{borderColor: "rgba(70,160,109,0.72)"}}/>
                            <i className="right" style={{borderColor: "rgba(70,160,109,0.98)"}}/>
                        </a>
                        <p>数据收集</p>
                    </div>
                    <div className="ph">
                        <a href="#analysis"
                           className="analysis phase"
                           key={"analysis"}
                           onMouseOut={this.handleMouseOut}
                           onMouseOver={this.handleMouseOver.bind(this, "analysis")}>
                            <i className="right" style={{borderColor: "rgba(166,185,107,0.43)"}}/>
                            <i className="right" style={{borderColor: "rgba(166,185,107,0.72)"}}/>
                            <i className="right" style={{borderColor: "rgba(166,185,107,0.98)"}}/>
                        </a>
                        <p>数据分析</p>
                    </div>
                    <div className="ph">
                        <a href="#presentation"
                           className="presentation phase"
                           key="presentation"
                           onMouseOut={this.handleMouseOut}
                           onMouseOver={this.handleMouseOver.bind(this, "presentation")}>
                            <i className="right" style={{borderColor: "rgba(176,88,161,0.43)"}}/>
                            <i className="right" style={{borderColor: "rgba(176,88,161,0.72)"}}/>
                            <i className="right" style={{borderColor: "rgba(176,88,161,0.98)"}}/>
                        </a>
                        <p>结果呈现</p>
                    </div>

                </ReactCSSTransitionGroup>
{/*                <div className="phase-text">
                    <h3 style={{marginLeft: textPosition[this.state.hover]}}>{phaseText[this.state.hover]}</h3>
                </div>*/}
            </div>
        )
    }
}
import React from 'react'
import './ResPage.css'
import { Card, Popover } from 'antd';
import DocuSky from "../img/docusky.png";
import Voyant from "../img/voyant.png";
import HathiTrust from "../img/hathitrust.png";
import Lexos from "../img/lexos.png";
import Gephi from "../img/gephi.png";
import Pajek from "../img/pajek.png";
import NodeXL from "../img/nodexl.png";
import Drupal from '../img/drupal.png'
import WordPress from '../img/wordpress.png'
import Omeka from '../img/omeka.png'
import Neatline from '../img/neatline.png'
import D3 from '../img/d3.svg'
import Vistorian from '../img/vistorian.png'
import Palladio from '../img/palladio.png'
import MapStory from '../img/mapstory.png'
import Latex from '../img/latex.svg'
import {Links} from "./Nav";

class Presentation extends React.Component{
    render() {
        const gridStyle = {
            width: '50%',
            textAlign: 'center',
        };

        return (
            <div className="resPage presentation">
                <Card className="substage-card pr-visualization" bordered={false}>
                    <div className="substage">
                        <div className="substage-title">可视化</div>
                        <Card title="文本挖掘" className="tool-card">
                            <Card.Grid style={{width: '50%', height: "80px"}}>
                                <Popover content={<Links website="https://docusky.org.tw/DocuSky/index.html" drupal="https://pkudh.org/docusky"/>} >
                                    <img src={DocuSky} style={{maxWidth:"100%"}} />
                                </Popover>
                            </Card.Grid>
                            <Card.Grid style={{width: '50%', height: "80px"}}>
                                <Popover content={<Links website="https://voyant-tools.org/" />} >
                                    <img src={Voyant} style={{maxWidth:"100%"}} />
                                </Popover>
                            </Card.Grid>
                            <Card.Grid style={{width: '50%', height: "80px"}}>
                                <Popover content={<Links website="https://www.hathitrust.org/" drupal="https://programminghistorian.org/en/lessons/text-mining-with-extracted-features"/>} >
                                    <img src={HathiTrust} style={{maxWidth:"100%"}} />
                                </Popover>
                            </Card.Grid>
                            <Card.Grid style={{width: '50%', height: "80px"}}>
                                <Popover content={<Links website="https://neatline.org/" />} >
                                    <img src={Neatline} style={{maxWidth:"100%"}} />
                                </Popover>
                            </Card.Grid>
                            <Card.Grid style={{width: '50%', height: "120px"}}>
                                <Popover content={<Links website="https://wheatoncollege.edu/academics/special-projects-initiatives/lexomics/lexos-installers/" />} >
                                    <img src={Lexos} style={{maxWidth:"100%"}}/>
                                </Popover>
                            </Card.Grid>
                            <Card.Grid style={{width: '50%', height: "120px"}}>
                                <Popover content={<Links website="https://d3js.org/" drupal="https://www.d3-graph-gallery.com/index.html"/>} >
                                    <img src={D3} style={{maxWidth:"100%"}}/>
                                </Popover>
                            </Card.Grid>
                        </Card>
                        <Card title="网络分析" className="tool-card">
                            <Card.Grid style={{width: '50%', height: "80px"}}>
                                <Popover content={<Links website="https://gephi.org/" drupal="https://gephi.org/tutorials/gephi-tutorial-quick_start.pdf"/>} >
                                    <img src={Gephi} style={{maxWidth:"100%"}} />
                                </Popover>
                            </Card.Grid>
                            <Card.Grid style={{width: '50%', height: "80px"}}>
                                <Popover content={<Links website="http://vlado.fmf.uni-lj.si/pub/networks/pajek/" />} >
                                    <img src={Pajek} style={{maxWidth:"100%"}} />
                                </Popover>
                            </Card.Grid>
                            <Card.Grid style={{width: '50%', height: "80px"}}>
                                <Popover content={<Links website="https://archive.codeplex.com/?p=nodexl" />} >
                                    <img src={NodeXL} style={{maxWidth:"100%"}} />
                                </Popover>
                            </Card.Grid>
                            <Card.Grid style={{width: '50%', height: "80px"}}>
                                <Popover content={<Links website="https://ezlinavis.dracor.org/" />} >
                                    <h2 style={{color: '#000000', fontSize: "1.3em", textAlign: "center", lineHeight: "1.8em", marginBottom: 0}}>Easy Linavis</h2>
                                </Popover>
                            </Card.Grid>
                            <Card.Grid style={{width: '100%', height: "100px"}}>
                                <Popover content={<Links website="https://vistorian.wordpress.com/" />} >
                                    <img src={Vistorian} style={{maxHeight:"100%"}} />
                                </Popover>
                            </Card.Grid>

                            <Card.Grid style={{width: '40%', height: "100px"}}>
                                <Popover content={<Links website="https://hdlab.stanford.edu/palladio/" />} >
                                    <img src={Palladio} style={{maxWidth:"100%"}} />
                                </Popover>
                            </Card.Grid>
                            <Card.Grid style={{width: '40%', height: "100px"}}>
                                <Popover content={<Links website="https://d3js.org/" drupal="https://www.d3-graph-gallery.com/index.html"/>} >
                                    <img src={D3} style={{maxWidth:"100%"}} />
                                </Popover>
                            </Card.Grid>

                        </Card>
                        <Card title="地理空间分析" className="tool-card">
                            <Card.Grid style={{width: '70%', height: "80px"}}>
                                <Popover content={<Links website="https://mapstory.org/" />} >
                                    <img src={MapStory} style={{maxWidth:"100%"}} />
                                </Popover>
                            </Card.Grid>
                            <Card.Grid style={{width: '30%', height: "80px"}}>
                                <Popover content={<Links website="https://hdlab.stanford.edu/palladio/" />} >
                                    <img src={Palladio} style={{maxWidth:"100%"}} />
                                </Popover>
                            </Card.Grid>
                            <Card.Grid style={{width: '30%', height: "100px"}}>
                                <Popover content={<Links website="https://d3js.org/" drupal="https://www.d3-graph-gallery.com/index.html"/>} >
                                    <img src={D3} style={{maxHeight:"100%"}} />
                                </Popover>
                            </Card.Grid>
                            <Card.Grid style={{width: '70%', height: "100px"}}>
                                <Popover content={<Links website="https://vistorian.wordpress.com/" />} >
                                    <img src={Vistorian} style={{maxHeight:"100%"}} />
                                </Popover>
                            </Card.Grid>
                            <Card.Grid style={{width: '100%', height: "80px"}}>
                                <Popover content={<Links website="https://storymap.knightlab.com/" />} >
                                    <h2 style={{color: '#000000', textAlign: "center", lineHeight: "1.8em", marginBottom: 0}}>StoryMapJS</h2>
                                </Popover>
                            </Card.Grid>
                        </Card>

                        <Card title="时间分析" className="tool-card">
                            <Card.Grid style={{width: '50%', height: "60px"}}>
                                <Popover content={<Links website="https://timeline.knightlab.com/" />} >
                                    <h2 style={{fontSize: "1.2em", color: '#000000', textAlign: "center", lineHeight: "1.2em", height: "1.2em", marginBottom: 0}}>TimeLineJS</h2>
                                </Popover>
                            </Card.Grid>
                            <Card.Grid style={{width: '50%', height: "60px"}}>
                                <Popover content={<Links website="https://storyline.knightlab.com/" />} >
                                    <h2 style={{fontSize: "1.2em", color: '#000000', textAlign: "center", lineHeight: "1.2em", height: "1.2em", marginBottom: 0}}>StoryLineJS</h2>
                                </Popover>
                            </Card.Grid>
                            <Card.Grid style={{width: '33%', height: "100px"}}>
                                <Popover content={<Links website="https://docusky.org.tw/DocuSky/index.html" />} >
                                    <h2 style={{fontSize: "1.2em", color: '#000000', textAlign: "center", lineHeight: "1.2em", height: "1.2em", marginBottom: 0}}>Tiki Toki</h2>
                                </Popover>
                            </Card.Grid>
                            <Card.Grid style={{width: '33%', height: "100px"}}>
                                <Popover content={<Links website="https://www.tiki-toki.com/" />} >
                                    <h2 style={{fontSize: "1.2em", color: '#000000', textAlign: "center", lineHeight: "1.2em", height: "1.2em", marginBottom: 0}}>Chronos</h2>
                                </Popover>
                            </Card.Grid>
                            <Card.Grid style={{width: '34%', height: "100px"}}>
                                <Popover content={<Links website="https://d3js.org/" drupal="https://www.d3-graph-gallery.com/index.html"/>} >
                                    <img src={D3} style={{maxHeight:"100%"}} />
                                </Popover>
                            </Card.Grid>

                        </Card>
                    </div>
                </Card>
                <div className="substage-2">
                    <Card className="substage-card pr-development" bordered={false}>
                        <div className="substage" style={{width: "100%"}}>
                            <div className="substage-title" style={{marginRight: "3px"}}>系统开发</div>
                            <Card className="tool-card" style={{width: "100%"}}>
                                <Card.Grid style={{width: '33%', height: "100px"}}>
                                    <Popover content={<Links website="https://omeka.org/" drupal="https://omeka.org/s/docs/user-manual/"/>} >
                                        <img src={Omeka} style={{maxHeight:"100%"}} />
                                    </Popover>
                                </Card.Grid>
                                <Card.Grid style={{width: '33%', height: "100px"}}>
                                    <Popover content={<Links website="https://www.drupal.org/" />} >
                                        <img src={Drupal} style={{maxWidth:"100%"}} />
                                    </Popover>
                                </Card.Grid>
                                <Card.Grid style={{width: '34%', height: "100px"}}>
                                    <Popover content={<Links website="http://www.worldpress.com/" />} >
                                        <img src={WordPress} style={{maxWidth:"100%"}} />
                                    </Popover>
                                </Card.Grid>
                            </Card>
                        </div>
                    </Card>
                    <Card className="substage-card pr-writing" bordered={false}>
                        <div className="substage">
                            <div className="substage-title" style={{marginRight: "3px"}}>论文写作</div>
                            <Card className="tool-card" style={{width: "100%"}}>
                                <Card.Grid style={{width: '100%', height: "100px"}}>
                                    <Popover content={<Links website="https://www.latex-project.org/" />} >
                                        <img src={Latex} style={{maxHeight:"100%"}} />
                                    </Popover>
                                </Card.Grid>
                            </Card>
                        </div>
                    </Card>
                </div>

            </div>
        )
    }
}

export default Presentation;
import React from 'react'
import './ResPage.css'
import { Card, Popover } from 'antd';
import {Links} from "./Nav";

class Formulation extends React.Component{
    render() {
        const gridStyle = {
            width: '50%',
            textAlign: 'center',
        };

        return (
            <div className="resPage formulation">
                <Card className="substage-card fo-reading" bordered={false} style={{height: "50%"}}>
                    <div className="substage">
                        <div className="substage-title">阅读</div>
                        <Card title="文本挖掘" className="tool-card">
                            <Card.Grid style={{width: '100%', height: "120px"}}>
                                <Popover content={<Links website="https://arxiv.org/abs/1501.00960" />} >
                                    Characterizing the Google Books Corpus: Strong Limits to Inferences of Socio-Cultural and Linguistic Evolution
                                </Popover>
                            </Card.Grid>
                            <Card.Grid style={{width: '100%', height: "150px"}}>
                                <Popover content={<Links drupal="https://pkudh.org/henry" website="https://arxiv.org/abs/1911.05652" />} >
                                    Relative contributions of Shakespeare and Fletcher in Henry VIII: An Analysis Based on Most Frequent Words and Most Frequent Rhythmic Patterns
                                </Popover>
                            </Card.Grid>
                        </Card>
                        <Card title="网络分析" className="tool-card">
                            <Card.Grid style={{width: '100%', height: "100px"}}>
                                <Popover content={<Links drupal="https://pkudh.org/dabo" website="https://kns.cnki.net/KCMS/detail/detail.aspx?dbcode=CJFQ&dbname=CJFDLAST2018&filename=SDSK201809011&uid=WEEvREcwSlJHSldRa1Fhb09pSnNvVW4wK1F2WVRHa0tqcFNKWlJkSlIvRT0=$9A4hF_YAuvQ5obgVAqNKPCYcEjKensW4ggI8Fm4gTkoUKaID8j8gFw!!&v=MDU1NTExVDNxVHJXTTFGckNVUjdxZlpPZHRGeXpnVkwzT05pbllaYkc0SDluTXBvOUVaWVI4ZVgxTHV4WVM3RGg="/>}>
                                    Social Network Analysis and Character Functionality of Trilogy Da Bo
                                </Popover>
                            </Card.Grid>
                            <Card.Grid style={{width: '100%', height: "100px"}}>
                                    How to Find Centered Social Network around Fengshu Post with CBDB
                            </Card.Grid>
                        </Card>
                        <Card title="地理空间分析" className="tool-card">
                            <Card.Grid style={{width: '100%', height: "100px"}}>
                                <Popover content={<Links drupal="https://pkudh.org/networkframework" website="https://science.sciencemag.org/content/345/6196/558" />} >
                                    A Network Framework of Cultural History
                                </Popover>
                            </Card.Grid>
                        </Card>

                        <Card title="时间分析" className="tool-card">
                            <Card.Grid style={{width: '100%', height: "60px"}}>
                                To be added.
                            </Card.Grid>
                        </Card>
                    </div>
                </Card>
                <Card className="substage-card fo-communicating" bordered={false} style={{height: "15%", width: "100%"}}>
                    <div className="substage">
                        <div className="substage-title" >交流</div>
                        <Card className="tool-card" style={{width: "100%", marginLeft: "20px"}}>
                            <Card.Grid style={{width: '100%', height: "80px"}}>
                                <h2 style={{color: '#000000', textAlign: "center", lineHeight: "1.8em", marginBottom: 0}}>Digital Humanities Forum @PKU</h2>
                            </Card.Grid>
                        </Card>
                    </div>
                </Card>
            </div>
        )
    }
}

export default Formulation;
import React from "react";
import { Card, Popover } from 'antd'
import 'antd/dist/antd.css'
import './ResPage.css'
import DocuSky from '../img/docusky.png'
import Voyant from '../img/voyant.png'
import HathiTrust from '../img/hathitrust.png'
import Lexos from '../img/lexos.png'
import AntConc from '../img/AntConc.png'
import Gephi from '../img/gephi.png'
import Pajek from '../img/pajek.png'
import NodeXL from '../img/nodexl.png'
import WorldMap from '../img/worldmap.png'
import Carto from '../img/carto.svg'
import ArcGIS from '../img/arcgis.png'
import QGIS from '../img/qgis.png'
import EViews from '../img/eviews.svg'
import Zaitun from '../img/zaitun-title.png'
import {Links} from "./Nav";

class Analysis extends React.Component{
    render() {
        const gridStyle = {
            width: '50%',
            textAlign: 'center',
        };

        return (
            <div className="resPage analysis">
                <Card className="substage-card an-analysis" bordered={false}>
                    <div className="substage">
                        <div className="substage-title">分析</div>
                        <Card title="文本挖掘" className="tool-card">
                            <Card.Grid style={{width: '100%'}}>
                                <Popover content={<Links website="https://docusky.org.tw/DocuSky/index.html" drupal="https://pkudh.org/docusky"/>} >
                                    <img src={DocuSky} style={{maxWidth:"100%"}} />
                                </Popover>
                            </Card.Grid>
                            <Card.Grid style={{width: '100%'}}>
                                <Popover content={<Links website="https://voyant-tools.org/" />} >
                                    <img src={Voyant} style={{maxWidth:"100%"}} />
                                </Popover>
                            </Card.Grid>
                            <Card.Grid style={{width: '100%'}}>
                                <Popover content={<Links website="https://www.hathitrust.org/" drupal="https://programminghistorian.org/en/lessons/text-mining-with-extracted-features"/>} >
                                    <img src={HathiTrust} style={{maxWidth:"100%"}} />
                                </Popover>
                            </Card.Grid>
                            <Card.Grid style={gridStyle}>
                                <Popover content={<Links website="https://wheatoncollege.edu/academics/special-projects-initiatives/lexomics/lexos-installers/" />} >
                                    <img src={Lexos} />
                                </Popover>
                            </Card.Grid>
                            <Card.Grid style={gridStyle}>
                                <Popover content={<Links website="https://www.laurenceanthony.net/software/antconc/" />} >
                                    <img src={AntConc} />
                                </Popover>
                            </Card.Grid>
                        </Card>
                        <Card title="network analysis" className="tool-card">
                            <Card.Grid style={{width: '100%'}}>
                                <Popover content={<Links website="https://gephi.org/" drupal="https://gephi.org/tutorials/gephi-tutorial-quick_start.pdf"/>} >
                                    <img src={Gephi} style={{maxWidth:"100%"}} />
                                </Popover>
                            </Card.Grid>
                            <Card.Grid style={{width: '100%'}}>
                                <Popover content={<Links website="http://vlado.fmf.uni-lj.si/pub/networks/pajek/" />} >
                                    <img src={Pajek} style={{maxWidth:"100px"}} />
                                </Popover>
                            </Card.Grid>
                            <Card.Grid style={{width: '100%'}}>
                                <Popover content={<Links website="https://archive.codeplex.com/?p=nodexl" />} >
                                    <img src={NodeXL} style={{maxWidth:"100px"}} />
                                </Popover>
                            </Card.Grid>
                        </Card>
                        <Card title="地理空间分析" className="tool-card">
                            <Card.Grid style={{width: '100%'}}>
                                <Popover content={<Links website="http://worldmap.harvard.edu/"/>}>
                                    <img src={WorldMap} style={{maxWidth:"100%"}} />
                                </Popover>
                            </Card.Grid>
                            <Card.Grid style={{width: '100%'}}>
                                <Popover content={<Links website="https://carto.com/"/>}>
                                    <img src={Carto} style={{maxWidth:"100%"}} />
                                </Popover>
                            </Card.Grid>
                            <Card.Grid style={gridStyle}>
                                <Popover content={<Links website="https://www.arcgis.com/index.html"/>}>
                                    <img src={ArcGIS} style={{maxWidth:"100%"}} />
                                </Popover>
                            </Card.Grid>
                            <Card.Grid style={gridStyle}>
                                <Popover content={<Links website="https://qgis.org/en/site/" drupal="https://programminghistorian.org/en/lessons/geocoding-qgis"/>}>
                                    <img src={QGIS} style={{maxWidth:"100%"}} />
                                </Popover>
                            </Card.Grid>
                        </Card>

                        <Card title="时间分析" className="tool-card">
                            <Card.Grid style={{width: '100%'}}>
                                <Popover content={<Links website="http://www.eviews.com/"/>}>
                                    <img src={EViews} style={{maxWidth:"100%"}} />
                                </Popover>
                            </Card.Grid>
                            <Card.Grid style={{width: '100%'}}>
                                <div className="group-horizontal">
                                    <Popover content={<Links website="http://www.zaitunsoftware.com/"/>}>
                                        <img src={Zaitun} style={{maxWidth:"100%", backgroundColor: '#57635e'}} />
                                    </Popover>
                                </div>
                            </Card.Grid>
                        </Card>
                    </div>
                </Card>
                {/*<Card className="substage-card an-interpretation" bordered={false}>
                    <div className="substage">
                        <div className="substage-title" style={{fontSize: "0.6em"}}>INTERPRETATION</div>

                    </div>
                </Card>*/}
            </div>
        )
    }
}

export default Analysis;
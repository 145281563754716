import React from 'react';
import './App.css';
import { SectionsContainer, Section } from 'react-fullpage'
import { Nav, Top } from './components/Nav'
import Analysis from "./components/Analysis";
import Formulation from './components/Formulation'
import Landing from './components/Landing'
import Collection from "./components/Collection";
import Presentation from "./components/Presentation";

class App extends React.Component {
  render() {
      let options = {
          activeClass:          'active', // the class that is appended to the sections links
          anchors:              ["home", "formulation", "collection", "analysis", "presentation"], // the anchors for each sections
          arrowNavigation:      true, // use arrow keys
          className:            'SectionContainer', // the class name for the section container
          delay:                1000, // the scroll animation speed
          navigation:           true, // use dots navigation
          scrollBar:            false, // use the browser default scrollbar
          sectionClassName:     'Section', // the section class name
          sectionPaddingTop:    '0', // the section top padding
          sectionPaddingBottom: '0', // the section bottom padding
          verticalAlign:        false // align the content of each section vertical
      };

    return (
        <div>
            <div className="footer">
                <Top />
            </div>
            <SectionsContainer {...options}>
                <Section>
                    <Landing />
                </Section>
                <Section>
                    <Formulation />
                </Section>
                <Section>
                    <Collection />
                </Section>
                <Section>
                    <Analysis />
                </Section>
                <Section>
                    <Presentation />
                </Section>
            </SectionsContainer>
        </div>
    );
  }
}

export default App;
